import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Text } from "../../components/Core"

const SpanishSearchResultsPage = ({ data, location }) => {
  const { siteMetadata } = data.site

  if (
    location.state &&
    location.state.suggestions &&
    location.state.searchInput &&
    location.state.searchTime
  ) {
    const results = location.state.suggestions
    const input = location.state.searchInput
    const { searchTime } = location.state
    const searchResults = Object.keys(results).map(key => {
      if (key !== "key") {
        const heading = results[key].searchHeading
        const paragraph = results[key].metaDescription
        let url = results[key].url || results[key].title
        // url = url.toLowerCase();
        if (url.charAt(0) !== "/") url = `/${url}`

        return (
          <div key={url} className="single-result">
            <Link to={url}>
              <Text as="h6" className="modified-h6" text={heading} />
            </Link>
            <Text as="p" text={paragraph} />
            <Link to={url}>{`${siteMetadata.siteUrl}${url}`}</Link>
          </div>
        )
      }
    })

    const resultsLength = Object.keys(results).length

    return (
      <Layout language="es">
        <SEO
          title="Resultados de la Búsqueda"
          description="Resultados de la búsqueda para Oral Surgery Specialists of Oklahoma"
          robots="noindex"
          lang="es"
        />
        <section className="com-content search-results-page">
          <div className="container">
            <div className="columns">
              <div className="column" />
              <div className="column is-20">
                <h2>Resultados de la Búsqueda</h2>
                <h6>
                  {resultsLength} resultado{resultsLength === 1 ? "" : "s"} para
                  "{input}"
                </h6>
                {resultsLength > 0 && (
                  <div className="search-results-wrapper">{searchResults}</div>
                )}
              </div>
              <div className="column" />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
  return (
    <Layout language="es">
      <SEO
        lang="es"
        title="Resultados de la Búsqueda"
        description="Resultados de la búsqueda para Oral Surgery Specialists of Oklahoma"
        robots="noindex"
      />
      <section className="com-content">
        <div className="container">
          <div className="columns">
            <div className="column" />
            <div className="column is-20">
              <h2>Resultados de la Búsqueda</h2>
              <h6>0 resultados encontrados</h6>
            </div>
            <div className="column" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query searchQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        fullPracticeName
      }
    }
  }
`

export default SpanishSearchResultsPage
